export const URL = {
  // API_BASE_URL: "https://phed.devhubtech.com",
//  API_BASE_URL: "http://192.168.0.13:8000",
   API_BASE_URL: "https://rrjtanker.com",

  all_vehicles: "/all_vehicles",
  all_vehicle_jen: "/all_vehicle_jen",
  all_vehicles_rollwise : "/all_vehicles_rollwise",
  getVendorList: "/api/getAllVendor",
  getDiscussionList: "/api/discussions/getDiscussionsList",
  getDiscussion: "/api/discussions/getDiscussion",
  getdiscommentlist: "/api/discomment/getdiscommentlist",
  discommentAdd: "/api/discomment/add",
  getDisCategoryList: "/api/category/getCategoryList",
  createUser: "/api/users/add",
  login: "/api/signin",
  studentSignup: "/api/student/signup",
  studentVerfiyOtp: "/api/student/verfiyOtp",
  studentCheckEmailExist: "/api/student/checkEmailExist",
  studentCheckMobileExist: "/api/student/checkMobileExist",
  update_user: "/update_user",
  updateProfile: "/updateProfile",
  getAllUniversity: "/api/University/getAllUniversity",
  getuniversities: "/api/University/getuniversities",
  fineUniversityBySlug: "/api/university/fineUniversityBySlug",
  getrankinguniversities: "/api/university/getrankinguniversities",
  deleteProfile: "/deleteProfile",

  getDiscussionsbyId: "/api/discussions/getDiscussionsbyId",
  getUniversityById: "/api/University/getUniversityById",
  getAllUniversitiesComment: "/api/University/getAllUniversitiesComment",
  addUniversityComment: "/api/University/addUniversityComment",
  getAllUniCourses: "/api/UniversityCourse/getAllCourses",
  getAllCourses: "/api/Course/getAllCourses",


  getAllCounsellor: "/api/Counsellor/getAllCounsellor",
  getCounsellorInfo: "/api/counsellor/getCounsellorInfo",


  getCounsellorbyId: "/api/counsellor/getCounsellorbyId",
  getCounsellorBySlug: "/api/counsellor/getCounsellorBySlug",
  getcoachcounsellor: "/api/coach/getcoachcounsellor",



  getScheduleBookings: "/getSchduleBookings",
  updateScheduleBooking: "/updateScheduleBooking",
  createScheduleBooking: "/createScheduleBooking",
  getPermissionsList: "/permission",
  rate: "/rate",
  circle: "/circle",
  user: "/user",
  userRoleWise: "/userRoleWise",
  department: '/department',

  getAllCircles: "/getAllCircles",
  userprofile: "/userprofile",


  getRoleList: "api/roles",
  createRoles: "/api/createRoles",
  createProfile: "/createProfile",
  getAllProfile: "/getAllProfile",



  assignUserProfile: "/assignUserProfile",


  // all_vehicles: "/all_vehicles",
  all_vehicles_vendor: "/all_vehicles_vendor",
  dashboard: "/dashboard",
  create_booking: "/create_booking",
  repeatBooking: "/repeatBooking",




  create_vehicle: "/create_vehicle",
  add_vehicle_doc: "/add_vehicle_doc",
  update_vehicle_doc: "/update_vehicle_doc",
  vehicleDetail: "/vehicleDetail",
  assign_vehicle_vendor: "/assign_vehicle_vendor",
  vehicle_delete: "/vehicle_delete",


  add_station: "/add_station",
  all_filling_stations: "/all_filling_stations",
  assign_manager_station: "/assign_manager_station",
  fillingStationDetails: "/fillingStationDetails",
  delete_filling_station: "/delete_filling_station",


  documents: "/documents",
  activeInactive: "/activeInactive",
  documents_update: "/documents_update",
  documentsApprove: "/documentsApprove",



  user_destination: "/user_destination",
  get_user_destination: "/get_user_destination",
  delete_user_destination: "/delete_user_destination",
  assign_driver: "/assign_driver",
  all_vehicle_driver: "/all_vehicle_driver",
  deleteAssignDriver: "/deleteAssignDriver",
  getUsersForLogin: "/getUsersForLogin",
  cheack_user: "/cheack_user",
  getAllBookings: "/getAllBookings",
  getCancelBookings: "/getCancelBookings",
  getCurrentBookings: "/getCurrentBookings",
  getCompleteBookings: "/getCompleteBookings",
  geBookingsByStatus: "/geBookingsByStatus",
  updateBooking: "/updateBooking",
  // getCompleteBookings: "/getCompleteBookings",
  // report by lokesh
  getReports: "/getReports",



  circleList: "/api/circle",







  roles: "/api/roles",












};
